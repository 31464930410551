$sigFont: antique-olive, sans-serif;
$signatureFont: $sigFont;
$sigFontWide: antique-olive, sans-serif;
$deco: antique-olive, sans-serif;
$white: #fff;
$black: #000;
// $red: #aa0d00;
$yellow: #F5C84B;
// $red: rgb(170,0,0);
$green: rgb(186, 218, 85);
$pink: rgb(255, 179, 138);
$red: $pink;
// $red: #eb038d;
$pink:  $red;
// $darkRed: rgb(116, 0, 0);
$darkRed: rgb(247, 134, 73);
$orange: #f1751b;
$darkOrange: #984911;
// $background-color: #fffaec;
$background-color: rgb(215,228,237);
$purpleRadio: radial-gradient(circle, rgba(106,0,161,0.8925945378151261) 25%, rgba(156,4,95,0.7301295518207283) 100%);

$max-site-width: 840px;
$brown: #743d2c;

