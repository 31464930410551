@import '_vars.scss';
.whats-new {
  .header {
    max-width: $max-site-width;
    margin: 0 auto;
    font-size: 2em;
  }
  .link-wrapper {
    text-align: center;
    .order-online-button {
      margin: 0.5em;
      display: inline-block;
      font-size: 5vw;
    }
  }
}

.whats-new__content {
  .header {
    text-align: center;
    width: 100%;
    margin-bottom: 1em;
    img {
      max-width: 200px;
    }
  }
  .catering-section {
    position: relative;
    // display: flex;
    // flex-wrap: wrap;
    // line-height: 1.5em;
    
    // // background: white;
    // padding: 2em 3.5em;
    // max-width: 600px;
    // margin: 0 auto;
    .content {
      position:absolute;
      top: 4em;
      width: 100%;
      text-align: center;
    }
    h2 {
      img {
        max-width: 400px;
      }
    }
  }
  .catering-bg {
   width: 100%;
   
  //  height: 300px;
  }
  .catering-bg-warpper {
    max-width: 800px;
    margin: 0 auto;
    width: 90%;
    // height: 300px;
  }
  .catering-text {
    margin: 0 auto;
    width: 80%;
    font-size: 1.5em;
    color: $red;
  }
  
  .about-us {
    font-size: 0.8em;
    display: flex;
    flex-wrap: wrap;
    line-height: 1.5em;
    
    background: white;
    padding: 2em 3.5em;
    max-width: 600px;
    margin: 0 auto;
    .text-content {
      width: 50%;
    }
    p {
      margin-bottom: 1.5em;
    }
    .menu-link-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      a.menu-link {
        font-weight: 600;
        border: 3px solid black;
        padding: 0.5em 1em;
        border-radius: 1em;
        color: inherit;
        text-decoration: none;
      }
    }
  }
}