.store-nav {
  display: flex;
  .nav-item {
    padding: 0.5em;
  }
  .nav-link.active {
    font-weight: 600;
    .logo {
      border: 4px solid black;
      border-radius: 0.2em;
      // background: black;
    }
  }
}
.page  {
  margin: 0;
}
.page.curry-me {
  background: url(https://afag.imgix.net/los-angeles-food-factory/curryme-bg.jpg?w=1200&auto=format);
}
.page.the-bab {
  // background: black;
}

.kst .menu-item {
  .photo-container {
    width: 300px;
    img {
      
      border-radius: 0.5em;
      overflow: hidden;
    }
  }
}
.curry-me {
  .product-link {
    background: black;
    color: rgb(254, 196, 50);
  }
  // .logo {
  //   width: 150px;
  //   height: 150px;
  //   background: url("https://afag.imgix.net/los-angeles-food-factory/curry-me-logo.png?w=300&auto=format") no-repeat;
  // }

}
